// ** Default import

import Spinner from '@src/components/UI/Spinner';
import React, {
  useState,
  Fragment,
  Suspense,
  useContext,
  useTransition,
} from 'react';

// ** Utils

import { useTranslation } from 'react-i18next';

import NotificationContext from '@context/NotificationContext';

import { useLocation } from '@reach/router';
import { Link } from 'gatsby';

import { classNames } from '@utility/Utils';
import NotificationBadge from '@components/UI/NotificationBadge';

const RequestProcessed = React.lazy(() =>
  import('@pages/validation/requestProcessed')
);
const RequestToProcess = React.lazy(() =>
  import('@pages/validation/requestToProcess')
);
const RequestCancel = React.lazy(() =>
  import('@pages/validation/requestCancel')
);

const Validation = () => {
  const { t } = useTranslation();

  const { getValidationToProcessNotif, getValidationToCancelNotif } =
    useContext(NotificationContext);
  const [isPending, startTransition] = useTransition();
  const location = useLocation();

  const initialTabs = [
    {
      label: t('requestToProcess'),
      name: '',
      content: <RequestToProcess />,
      active: location.hash.replace('#', '') === '',
    },
    {
      label: t('requestProcessed'),
      name: 'validationToProcessed',
      content: <RequestProcessed />,
      active: location.hash.replace('#', '') === 'validationToProcessed',
    },
    {
      label: t('requestCancel'),
      name: 'validationToCancel',
      content: <RequestCancel />,
      active: location.hash.replace('#', '') === 'validationToCancel',
    },
  ];
  const [tabs, setTabs] = useState(initialTabs);

  // set the tab active
  const setActive = (tab) => {
    startTransition(() => {
      setTabs((prevState) => {
        return prevState.map((t) => {
          return {
            ...t,
            active: t.name === tab,
          };
        });
      });
    });
  };

  return (
    <>
      <h1 className={'mb-6 text-2xl'}>{t('validation')}</h1>
      <div className='bg-white p-7 rounded-md min-h-[101vh]'>
        <nav className='flex justify-around' aria-label='Tabs'>
          {tabs.map((tab, index) => (
            <Link
              key={index}
              to={`#${tab.name}`}
              onClick={() => setActive(tab.name)}
              className={classNames(
                tab.active
                  ? 'border-b-2 border-primary1 transition duration-300 ease-in'
                  : 'border-b-2 border-[#E1060020] transition duration-300 ease-in-out',
                'text-gray-500 w-full flex items-center justify-center gap-x-6 hover:text-gray-700 pb-2'
              )}
            >
              <span>{tab.label}</span>
              {tab.name !== 'validationToProcessed' &&
                (tab.name === 'validationToCancel'
                  ? getValidationToCancelNotif > 0 && (
                      <NotificationBadge
                        numberOfNotification={
                          getValidationToCancelNotif > 99
                            ? '99+'
                            : getValidationToCancelNotif
                        }
                      />
                    )
                  : getValidationToProcessNotif > 0 && (
                      <NotificationBadge
                        numberOfNotification={
                          getValidationToProcessNotif > 99
                            ? '99+'
                            : getValidationToProcessNotif
                        }
                      />
                    ))}
            </Link>
          ))}
        </nav>

        {isPending ? (
          <Spinner />
        ) : (
          <Suspense fallback={<Spinner />}>
            <div className={'mt-4'}>
              {tabs.map((tab, index) => (
                <Fragment key={index}>{tab.active && tab.content}</Fragment>
              ))}
            </div>
          </Suspense>
        )}
      </div>
    </>
  );
};

export default Validation;
